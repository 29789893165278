import axios from "axios";

const getPlanCoutes = (appId) => {
  return axios.get(`${process.env.REACT_APP_WELLI_API}/api/application/plan_cuotas/${appId}`);
}

const getApplicationsApi = (profileId) => {
  return axios.get(`${process.env.REACT_APP_WELLI_API}/api/applications/approved/${profileId}`);
}

const getContractApi = (appId) => {
  return axios.get(`${process.env.REACT_APP_WELLI_API}/api/contract/download/${appId}`, {
    responseType: "blob"
  });
}

const getCertificateFulfilledApi = (appId) => {
  return axios.get(`${process.env.REACT_APP_WELLI_API}/api/loans/fulfilled/download/${appId}`, {
      responseType: "blob"
    }
  );
}

export {
  getPlanCoutes,
  getApplicationsApi,
  getContractApi,
  getCertificateFulfilledApi
}