import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from '../../../Components/common/Input';
import Link from '@mui/material/Link';
import Btn from '../../../Components/common/Btn';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Modal from '../../../Components/common/Modal/Modal';
import InfoModal from './InfoModal';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCreateAccount from '../hooks/useCreateAccount';
import { sendOptLogin } from '../../../hooks/useOTP';
import ErrorLogin from './ErrorLogin';

const Register = ({
  url = 'assets/img/Welli_Logo-06.jpg',
  handleGoBack,
  type,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const navigate = useNavigate();
  const { createAccount, restart, fetching } = useCreateAccount();
  const [fetchingLogin, setFetchingLogin] = useState('idle');
  const [searchParams] = useSearchParams();
  
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    setShowModal(true);
  };

  const onSave = async (action) => {
    if (action == 'continue') {
      onLogin();
      return;
    }
    const success = await createAccount({
      phone: getValues('phone'),
    });

    if (success) {
      navigate('/auth_account'); // Redirige si el registro fue exitoso
    }
  };

  const onLogin = async () => {
    const phone = getValues('phone');
    setFetchingLogin('loading');

    const resp = await sendOptLogin({ phone: 57 + phone });
    if (resp) {
      setFetchingLogin('success');

      sessionStorage.setItem('celular', 57 + getValues('phone'));
      navigate('/auth_account?login=true');
    } else {
      setFetchingLogin('error');
      setShowModal(false);
      if (type == 'continue') {
        setShowModal(false);
        setErrorLogin(true);
        return;
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('rgxs') && sessionStorage.getItem('celular')) {
      setValue('phone', sessionStorage.getItem('celular').slice(2)); // 57 + phone;
    }
  }, []);

  useEffect(() => {
    const phone = searchParams.get('phone');
    if (phone) {
      const localPhone = phone.startsWith('57') ? phone.slice(2) : phone;
      setValue('phone', localPhone);
    }
  }, [])
  
  return (
    <>
      <div className='flex w-full md:w-[26rem]  flex-col center items-center gap-8 md:gap-12'>
        <img src={url} alt='Welli postura' className='w-32 ' />
        <section className='text-center'>
          <Typography
            variant='h5'
            fontSize={28}
            fontWeight={500}
            color={'black'}
            style={{ marginBottom: '1rem' }}
            gutterBottom
          >
            ¡Bienvenido!
          </Typography>

          <Typography
            variant='h5'
            fontSize={18}
            fontWeight={500}
            align='center'
            gutterBottom
          >
            <span className='text-gray-700'>
              Te enviaremos un código por WhatsApp para ingresar a Welli
            </span>
          </Typography>
        </section>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-8 md:gap-12'
        >
          <div className='w-full'>
            <Typography variant='body1' fontSize={14} gutterBottom>
              <span className='ml-2 text-gray-900'>
                Ingresa tu número de WhatsApp
              </span>
            </Typography>

            {/* Campo de entrada con validaciones */}

            <div>
              <Input
                className='w-full'
                type='number'
                {...register('phone', {
                  required: 'El número de celular es requerido',
                  pattern: {
                    value: /^3\d{9}$/,
                    message: 'El número de celular no es válido',
                  },
                  maxLength: {
                    value: 10,
                    message:
                      'El número de celular no debe ser mayor de 10 caracteres',
                  },
                  minLength: {
                    value: 10,
                    message:
                      'El número de celular no debe ser menor de 10 caracteres',
                  },
                })}
                startAdornment={
                  <div
                    style={{
                      display: ' inline-flex',
                      alignItems: 'center',
                      justifyCcontent: 'center',
                    }}
                  >
                    <img
                      className='w-6 h-6 mr-2 rounded-full object-cover object-center'
                      src='/assets/img/Flag_Colombia.jpg'
                    />
                    <span className='font-bold'>+57</span>
                  </div>
                }
                aria-label='Phone'
                placeholder='Número de celular'
              />
              {errors.phone && (
                <p className='text-sm text-red-500 mt-1'>
                  {errors.phone.message}
                </p>
              )}
            </div>

            <div className='mt-4 md:mt-8 '>
              <Typography
                variant='body1'
                textAlign={'center'}
                fontSize={13}
                gutterBottom
              >
                <span className='ml-2 text-gray-900'>
                  Al hacer clic en continuar, autorizas el
                  <Link
                    href='https://storage.googleapis.com/welli-static/documents/politica-tratamiento-datos-personales.pdf'
                    target='_blank'
                    rel='noopener'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    <span className='text-black underline decoration-gray-950	  underline-offset-2  font-bold'>
                      {' '}
                      tratamiento de tus datos personales{' '}
                    </span>
                  </Link>
                  según nuestra Política de Privacidad y aceptas los
                  <Link
                    href='/assets/documents/welli.pdf'
                    target='_blank'
                    rel='noopener'
                    style={{ color: 'black', textDecoration: 'none' }}
                    className='text-black font-bold'
                  >
                    <span className='text-black underline decoration-gray-950	  underline-offset-2  font-bold'>
                      {' '}
                      términos y condiciones de Welli{' '}
                    </span>
                  </Link>
                  . También aceptas que realicemos labores de cobranza a través
                  de canales autorizados de cobranza.
                </span>
              </Typography>
            </div>
          </div>
          <div className='w-full'>
            <Btn
              type='submit'
              color='secondary'
              className='relative w-full mb-4'
            >
              Continuar
              <KeyboardBackspaceIcon
                sx={{ fontSize: 30 }}
                className=' absolute top-[20%]  right-3 rotate-180 '
              />
            </Btn>

            <p
              onClick={() => handleGoBack()}
              className='w-full mb-4 text-slate-900 text-center cursor-pointer  font-bold '
            >
              Volver
            </p>
          </div>
        </form>
      </div>

      <Modal
        showClose={true}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          restart();
        }}
      >
        <InfoModal
          onCancel={() => {
            setShowModal(false);
            restart();
          }}
          onContinue={() => onSave(type)}
          fetching={fetching}
          fetchingLogin={fetchingLogin}
          onLogin={onLogin}
          phone={getValues('phone')}
          type={type}
          errorLogin={errorLogin}
        />
      </Modal>

      <Modal
        showClose={true}
        open={errorLogin}
        onClose={() => {
          setErrorLogin(false);
          restart();
        }}
      >
        <ErrorLogin
          onCancel={() => {
            setErrorLogin(false);
            restart();
          }}
          onSave={onSave}
          fetching={fetching}
          phone={getValues('phone')}
          type={type}
          errorLogin={errorLogin}
        />
      </Modal>
    </>
  );
};

export default Register;
