import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLoan } from "../api/dashboard";

export const fetchLoan = createAsyncThunk("loan/fetchByLoan", async (loan) => {
  const response = await getLoan(loan);
  return { ...response.data.data, facturas: response.data?.data?.facturas || [], currentBill:{} };
});

const formatCreditHub = (data) => ({
  aggregatedDebt: data?.aggregated_debt || {},
  segregatedDebt: data?.segregated_debt || {},
});

const initialState = {
  loan: [],
  application: {},
  bills: [],
  currentBill: {},
  creditHub: {},
  pays: [],
  loading: "idle", //'idle' | 'pending' | 'succeeded' | 'failed'
  error: "",
};

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    resetLoan: (state) => {
      state.loan = [];
      state.application = {};
      state.bills = [];
      state.pays = [];
      state.creditHub = [];
      state.currentBill = {};
      state.loading = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoan.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchLoan.fulfilled, (state, { payload }) => {
        return {
          loading: "succeded",
          loan: payload.credito,
          bills: payload.facturas,
          pays: payload.pagos,
          application: payload.application,
          creditHub: payload?.credit_hub?.response ? formatCreditHub(payload.credit_hub.response) : '',
          currentBill: payload.currentBill,
        };
      })
      .addCase(fetchLoan.rejected, (state, action) => {
        state.loan = [];
        state.bills = [];
        state.pays = [];
        state.currentBill = {};
        state.error = action.error.message;
      });
  },
});

export const { resetLoan } = loanSlice.actions;

export default loanSlice.reducer;

export const selectLoan = ({ loanReducer }) => loanReducer.loan;
export const selectBills = ({ loanReducer }) => loanReducer.bills;

export const selectCurrentBill = ({ loanReducer }) => loanReducer.currentBill;
export const selectCreditHub = ({ loanReducer }) => loanReducer.creditHub;
export const selectApplication = ({ loanReducer }) => loanReducer.application;
export const selectPays = ({ loanReducer }) => loanReducer.pays;
export const selectLoanError = ({ loanReducer }) => loanReducer.error;
export const selectLoanStatus = ({ loanReducer }) => loanReducer.loading;
