import * as React from 'react';
import Link from '@mui/material/Link';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { currencyFormat } from '../helpers/formaHelper';
import { createContractApi } from '../api/sign';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Btn from '../Components/common/Btn';
import { handleBack } from '../store/applicationStep';
import store from '../store';
import { MuiOtpInput } from 'mui-one-time-password-input';
import clsx from 'clsx';
import { useRef } from 'react';

const TIMER_SECONDS = 30;

export default function Sign(props) {
  const { formCompleted } = props;
  const [timeLeft, setTimeLeft] = useState(); // Tiempo inicial en segundos
  const timerRef = useRef(null); // Referencia para el temporizador

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [contract, setContract] = useState({});
  const [appId, setAppId] = useState(0);
  const [contractUrl, setContractUrl] = useState('');
  const [contractCreated, setContractCreated] = useState('idle');
  const [, setShowCreateContract] = useState(true);
  const navigate = useNavigate();
  const [showFailCodes, setShowFailCodes] = useState(false);
  const [showSign, setShowSign] = useState(false);
  useEffect(() => {
    return () => {
      // Limpia el temporizador al desmontar el componente
      clearInterval(timerRef.current);
    };
  }, []);

  const startTimer = () => {
    setTimeLeft(TIMER_SECONDS);
    // Limpia cualquier temporizador existente
    clearInterval(timerRef.current);

    // Configura un nuevo temporizador
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Detén el temporizador cuando llegue a 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const profileId = sessionStorage.getItem('profile_id');
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/applications/risk/${profileId}`
      )
      .then((resp) => {
        const id = resp.data.app.id;
        setContract(resp.data.risk);
        
        setAppId(id);
        sessionStorage.setItem('idapp', resp.data.app.id);
        sessionStorage.setItem('referencia_pago', resp.data.app.referencia_pago);
        sessionStorage.setItem('medico', resp.data.risk.medico);
        sessionStorage.setItem('monto', resp.data.risk.monto);
        setContractUrl(
          `${process.env.REACT_APP_WELLI_API}/api/contract/create/${id}`
        );
      })
      .catch(() => {});
  }, []);
  const saveDeceval = () => {
    axios
      .get(`${process.env.REACT_APP_WELLI_API}/api/deceval/create/${appId}`)
      .finally(() => {
        navigate('/applast');
      });
  };
  const onSubmit = (data) => {
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/contract/validate/${appId}/sms/${data.otpsms}`
      )
      .then(() => {
        formCompleted();
        axios
          .get(
            `${process.env.REACT_APP_WELLI_API}/api/contract/signed/${appId}`
          )
          .then(() => {
            saveDeceval();
          })
          .catch(() => {
            alert('EL contrato no ha podido ser enviado');
          });
      })
      .catch(() => {
        setShowFailCodes(true);
      });
  };

  const reenviaOtp = () => {
    //const email = sessionStorage.getItem("email");
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/contract/otp/retry/${appId}/sms`
      )
      .then(() => {})
      .catch(() => {
        alert('Los códigos no han sido reenviados correctamente');
      })
      .finally(() => startTimer());
  };

  const createContract = async () => {
    setContractCreated('pending');
    createContractApi(contractUrl)
      .then(() => {
        setContractCreated('succeded');
        setShowCreateContract(false);
        setShowSign(true);
        startTimer();
      })
      .catch(() => {
        setContractCreated('failed');
      });
  };

  return (
    <>
      <section className=' overflow-x-hidden w-full flex  flex-col content-center items-center justify-center bg-[#EDEEF8]'>
        <section className=' overflow-x-hidden w-full flex  flex-col  items-center px-6 py-8  md:px-12 md:pt-10 md:w-[34rem] '>
          {!showSign && (
            <>
              <h3 className='font-bold text-2xl text-center mb-2'>
                Revisa los datos de tu crédito
              </h3>

              <div className='w-full  border-t-2 border-secondary-500  px-4 py-4 shadow-md bg-white '>
                <div className='mb-8'>
                  <p className=' font-bold text-lg mb-2'>Resumen del crédito</p>

                  <p>
                    Monto a financiar:{' '}
                    <strong>${currencyFormat(contract.monto)}</strong>{' '}
                  </p>
                  <p>
                    Plazo: <strong>{contract.cuotas} meses </strong>{' '}
                  </p>
                  <p>
                    Valor Cuota Mensual:{' '}
                    <strong>
                      ${currencyFormat(contract.valor_cuota, false)}{' '}
                    </strong>
                  </p>

                  <p>
                    Transferir a : <strong> {contract.medico} </strong>{' '}
                  </p>
                </div>

                <div className='mb-8'>
                  <p className=' font-bold text-lg mb-2'>
                    Tasas y cargos incluidos en la cuota
                  </p>
                  <p>
                    Seguro de vida mensual:{' '}
                    <strong>
                      {' '}
                      ${currencyFormat(
                        parseInt(contract.seguro_vida_mensual)
                      )}{' '}
                    </strong>
                  </p>
                  <p>
                    Valor fianza mensual + IVA:{' '}
                    <strong>
                      {' '}
                      ${currencyFormat(parseInt(contract.valor_fga_con_iva))}{' '}
                    </strong>
                  </p>
                  {/* <p>Tasa de Interés %E.A.: {contract.tasa_interes_ea}</p> */}
                  <p>
                    Tasa de Interés :{' '}
                    <strong>
                      {' '}
                      {(contract.tasa_interes_mv * 100).toFixed(2)} % M.V.
                    </strong>
                  </p>
                </div>

                <div className='mb-4'>
                  <p className=' font-bold text-lg mb-2'>Pago de la cuota</p>
                  <p>
                    Fecha Primer Pago:{' '}
                    <strong>{contract.fecha_primer_pago} </strong>
                  </p>
                  <p>
                    Canales de recaudo:
                    <strong>
                      {' '}
                      Paga en línea por PSE o en efectivo en corresponsal
                      bancario Bancolombia{' '}
                    </strong>
                  </p>
                </div>
              </div>
              {/* 
              {showCreateContract && (
                <Grid item xs={12}>
                  <Button
                    disabled={contractCreated === 'pending'}
                    onClick={createContract}
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {contractCreated === 'pending'
                      ? 'Enviando códigos para firma digital...'
                      : 'Solicite el código para firmar el contrato'}
                  </Button>
                </Grid>
              )}
 */}
              <Btn
                type='submit'
                disabled={contractCreated === 'pending'}
                onClick={createContract}
                color='secondary'
                className=' w-full mb-4 mt-6 '
              >
                <span className='w-[90%]'>
                  {contractCreated === 'pending'
                    ? 'Enviando códigos para firma digital...'
                    : 'Solicite el código para firmar el contrato'}
                </span>
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 30 }}
                  className='right-3 rotate-180 '
                />
              </Btn>

              <p
                onClick={() => store.dispatch(handleBack())}
                className='w-full text-slate-900 text-center cursor-pointer  font-bold '
              >
                Volver
              </p>
            </>
          )}
          {showSign && (
            <>
              <img
                src={'assets/img/Firma_2_WELLI.png'}
                alt='Welli postura'
                className='w-32 '
              />
              <h3 className='font-bold text-2xl text-center mb-8'>
                Estás a un paso de firmar el contrato y el pagaré y tomar el
                crédito en firme
              </h3>

              <h3 className='text-lg text-center mb-4'>
                Ingresa el código que enviamos a tu WhatsApp para firmar
                digitalmente los documentos
              </h3>
              <div className='mt-4 mb-6 border-b-2 w-full border-gray-300'></div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className='flex flex-col gap-6 md:gap-12'
              >
                <section>
                  <MuiOtpInput
                    className={clsx(
                      'w-full otpInput ',
                      showFailCodes && 'danger'
                    )}
                    error
                    slotProps={{
                      root: { className: 'flex justify-between' }, // Estilos para el contenedor raíz
                      input: {
                        className:
                          'border-red-500 focus:bg-green-500 rounded-md text-center w-12',
                      }, // Estilos para los campos de entrada individuales
                    }}
                    length={4}
                    {...register('otpsms', {
                      required: 'Este campo es requerido',
                      maxLength: {
                        value: 4,
                        message: 'El código debe tener 4 dígitos',
                      },
                      minLength: {
                        value: 4,
                        message: 'El código debe tener 4 dígitos',
                      },
                    })}
                    value={watch('otpsms')}
                    onChange={(value) => setValue('otpsms', value)}
                  />

                  {errors.codes && (
                    <p className='text-red-500 mt-4'>{errors.codes.message}</p>
                  )}

                  {showFailCodes == 'rejected' && (
                    <p className='text-red-500 mt-4'>
                      Código inválido, por favor vuelve a intentarlo.
                    </p>
                  )}
                </section>

                <span className='ml-2 text-gray-900 text-center'>
                  Al firmar aceptas el
                  <Link
                    href={`${process.env.REACT_APP_WELLI_API}/api/contract/review_contract/${appId}`}
                    target='_blank'
                    rel='noopener'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    <span className='text-black underline decoration-gray-950	  underline-offset-2  font-bold'>
                      {' '}
                      contrato de crédito {' '}
                    </span>
                  </Link>
                  y
                  <Link
                    href={ `${process.env.REACT_APP_WELLI_API}/api/pagare/review/${appId}`}
                    target='_blank'
                    rel='noopener'
                    style={{ color: 'black', textDecoration: 'none' }}
                    className='text-black font-bold'
                  >
                    <span className='text-black underline decoration-gray-950	  underline-offset-2  font-bold'>
                      {' '}
                      el pagaré a favor de Welli{' '}
                    </span>
                  </Link>
                </span>

                <Btn type='submit' className='relative' color='secondary'>
                  Firmar contrato y pagaré
                </Btn>

                <section className='animate-fadeIn-text text-center text-lg font-bold mt-4'>
                  ¿No recibiste código?{' '}
                  {timeLeft > 0 ? (
                    <p className='text-primary-500 text-sm'>
                      {timeLeft} para solicitar el código de nuevo
                    </p>
                  ) : (
                    <span
                      onClick={reenviaOtp}
                      className='underline underline-offset-1 cursor-pointer'
                    >
                      Enviar de nuevo
                    </span>
                  )}
                </section>
              </form>
            </>
          )}
        </section>
      </section>
    </>
  );
}
