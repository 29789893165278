

const MIN_AMOUNT_VALUE = 180000
const MAX_AMOUNT_VALUE = 25000000
const MIN_FEE_AMOUNT = 30000
const MAX_AGE_TO_APPLY = 85

const COUTES_DEFAULT = 36;

const PAY_EXTRACT_AVAILABLES = "2025-01-01"

export {MIN_AMOUNT_VALUE , MAX_AMOUNT_VALUE ,MIN_FEE_AMOUNT, MAX_AGE_TO_APPLY, COUTES_DEFAULT, PAY_EXTRACT_AVAILABLES}