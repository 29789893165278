import { useState } from 'react';
import axios from 'axios';

const useCreateAccount = () => {
  const [fetching, setFetching] = useState('idle'); // Puede ser 'idle', 'loading', 'success', 'error'
  const [error, setError] = useState(null);

  const createAccount = async ({ phone}) => {
    setFetching('loading');
    setError(null);
    const phoneLocal = 57 + phone;
    try {
      const data = { phone: phoneLocal };
      const resp = await axios.post(
        `${process.env.REACT_APP_WELLI_API}/api/accounts-minimal`,
        data
      );

      sessionStorage.setItem('celular', resp.data.phone);

      setFetching('success');
      return true;
    } catch (err) {
      setFetching('error');
      setError(err?.response?.data?.message || 'Error al crear la cuenta');
      return false;
    }
  };

  const restart = () => {
    setFetching('idle');
    setError(null);
  };
  return { createAccount, restart, fetching, error };
};

export default useCreateAccount;
