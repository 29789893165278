export const currencyFormat = (number, showDecimals = true) => {
    if (number === undefined || number === null) return "";

    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";

    let formattedNumber = parseFloat(number).toFixed(2);
    let arr = formattedNumber.split(".");
    arr[0] = arr[0].replace(exp, rep);
    return showDecimals ? arr.join(".") : arr[0];
};
